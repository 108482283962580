.card {
  width: 200px;
  height: 150px;
  :global {
    .ant-card-body {
      height: 100%;
      padding: 0;

      .ant-row {
        height: 100%;
        // background-image: url('../../../assets/images/pages/canvas-page.png');
        background-size: contain;

        .ant-col {
          background-color: rgba(102, 118, 133, 0.74);
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          color: white;
          font-weight: bolder;
          font-size: larger;
        }
      }
    }
  }
}

.draggable {
  cursor: move;
  // position: absolute !important;
}
