.wrapper {
  height: 100vh;

  :global {
    .content {
      background-color: #fff;
    }

    .header {
      height: 7.5vh;
      background-color: #004d80;
      color: #fcffff;
      font-size: 4vh;
    }

    .box-section-gap {
      height: 2.5vh;
    }

    .box-section {
      .title {
        color: #004d81;
        font-size: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .box {
        width: 16.5vh;
        height: 16.5vh;
        border-radius: 2.25vh;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 9.5vh;
        font-weight: bolder;
      }
    }

    .gauge-section-gap {
      height: 2.5vh;
    }

    .gauge-section-header {
      font-size: 2.2vh;
      font-weight: bolder;
    }

    .gauge-section-header-gap {
      height: 2.5vh;
    }

    .gauges {
      font-size: 2.8vh;
      font-weight: bolder;
    }

    .map-section-gap {
      height: 2.5vh;
    }

    .map {
      min-height: 100px;
      height: 38vh;
    }

    .chart-section {
      .text {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1rem;
        font-weight: bold;
      }

      .chart {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 100%;

        // .chartjs-size-monitor {
        //   height: 100%;
        //   width: 100%;
        // }

        .chartjs-render-monitor {
          // width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
}
