@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

:root {
  font-family: 'Poppins', 'Open Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

html body {
  font-family: 'Poppins', 'Open Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7faff !important;
}

.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.leaflet-map {
  background: #ffffff !important;
  border: 0.1rem solid #4798d0 !important;
}

// .leaflet-heatmap-layer {
//   opacity: 1;
// }
