.root {
  height: 100vh;

  :global {
    .gauges-root {
      height: 35vh;

      .gauges-card {
        height: 35vh;
      }

      .name {
        height: 6vh;
      }

      h2 {
        color: #3672f8;
        font-size: 4vh;
        margin-bottom: 0;
      }

      .contact {
        height: 3vh;
      }

      .gauges {
        height: 22vh;
        overflow-x: auto;
      }
    }

    .charts-root {
      height: 60vh;

      .charts-card {
        height: 60vh;
      }

      .ant-carousel,
      .carousel,
      .slick-list,
      .slick-track {
        height: 56vh;

        canvas {
          height: 54vh !important;
        }
      }

      .carousel {
        overflow-y: hidden;
      }
    }
  }
}
