.root {
  max-width: 175px !important;
  width: 175px !important;
  min-width: 0 !important;

  // overflow: auto;
  // height: 100vh;
  // position: fixed !important;
  // left: 0;

  :global {
    .floorPlanIconCollapsed {
      padding-left: 20px !important;
    }

    .floorPlanIcon {
      padding-left: 20px !important;

      .ant-menu-item-icon {
        margin-right: 0 !important;
        min-width: 0px;
      }
    }
  }
}

.iconMenu {
  cursor: default !important;
  padding-top: 0.6rem !important;
  display: flex !important;
  margin-top: 0.8rem !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background-color: transparent !important;

  &::after {
    border-right: 0 !important;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.85) !important;
  }
  &:active {
    background: none !important;
  }
}

.siteLayout {
  :global {
    .ant-menu {
      height: 61px !important;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;

      &:hover {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    // .siteLayout-background {
    //   background: #fff !important;
    //   box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.07);
    //   height: 61px !important;
    // }
  }
}
