.wrapper {
  height: 100vh;
  background-image: url('../../../assets/images/airBalloon.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 160px;
  background-position-y: bottom;
  padding: 0;
  margin: 0;

  :global {
    .formSection {
      height: 100vh;
      background: #fff !important;
    }

    .logo {
      display: block;
      width: 90%;
      margin: 0 auto;
    }
  }
}
