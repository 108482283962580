.tableIcon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}

.crossIcon {
  position: absolute;
  right: -10px;
  top: -10px;
  cursor: pointer;
}
