.map {
  height: 100%;
}

.box {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 50%;
  overflow-wrap: break-word !important;
  cursor: pointer;
  text-align: center;
  // margin-bottom: 1.5rem;
  // margin: 0.5rem;

  border-width: 0.01rem;
  border-style: solid;
  border-radius: 1rem;
  border-color: rgb(228, 226, 226);

  // padding-bottom: 1rem;
}

.boxSelected {
  cursor: default;
  border-width: 0.2065rem;
  // border-color: gray !important;
  border-color: #2880ca !important;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.boxRoot {
  height: 67%;
  overflow-y: auto;
  overflow-x: hidden;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.gaugeCard {
  :global {
    .ant-card-body {
      height: 382px;
      overflow-y: auto;

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.navigation {
  background-color: #d9dbdb;
  height: 15%;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0.3rem;

  :global {
    button {
      font-size: 0.7rem;
      text-align: center;
      overflow-wrap: break-word !important;
      padding: 0;
    }
  }
}

.radioGroup {
  z-index: 9999;
  position: absolute;
  right: 4px;
  top: 12px;
  // background-color: #f1ebeb;
}
