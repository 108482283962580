.label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.disabledInput {
  background-color: #ccc !important;
}

.antdRadio {
  display: flex !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.inputErrorDiv {
  :global {
    label {
      color: #cc0033;
    }

    input,
    input:focus {
      border-color: #cc0033 !important;
    }

    span {
      border-color: #cc0033 !important;
    }

    .ant-select-selector,
    .ant-select-selector:focus {
      border-color: #cc0033 !important;
    }

    textarea,
    textarea:focus {
      border-color: #cc0033 !important;
    }
  }
}

.errorMsg {
  color: #cc0033;
  // display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
}
